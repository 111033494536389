import { Grid, Typography } from '@material-ui/core';
import config from '../../../../config';
import { GoogleLogin } from 'react-google-login';
import actions from '../../../../store/actions';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

function Navbar(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const log_in = (response) => {
        if(response.profileObj?.givenName && response.profileObj?.email){
            props.login({
                username: response.profileObj.givenName,
                email: response.profileObj.email,
                imageUrl: response.profileObj.imageUrl
            })
        } else {
            props.error("Can't Login");
        }
    }

    const handleLoginFailure = (response) => {
        console.log(response)
        // props.error("Can't Login");
    }

    const logout = () => {
        handleClose();
        props.logout();
    }

    return (
        <Grid container style={{padding: "20px"}} alignItems="center">
            <Grid item>
                <img src="https://assetsstatic.s3.ap-south-1.amazonaws.com/intutrack_logo.png" alt="" height="40" width="40"/>
            </Grid>
            <Grid item>
                <Typography style={{color: "#43425D", fontWeight: "600", fontFamily: "'Montserrat', sans-serif", marginLeft: "15px"}}>Accounts Payable</Typography>
            </Grid>
            <Grid item style={{marginLeft: "auto"}}>
                {
                    props.loggedIn ?
                        <>
                            <Avatar alt={props.username} src={props.imageUrl} onClick={handleClick} style={{cursor: "pointer"}}/>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose} style={{display: "flex", alignItems: "center", justify:"space-evenly"}}>
                                    <AccountBoxIcon style={{margin: "15px"}}/>{props.username}
                                </MenuItem>
                                <MenuItem onClick={logout} style={{display: "flex", alignItems: "center", justify:"space-evenly"}}>
                                    <ExitToAppIcon style={{margin: "15px"}}/>Logout
                                </MenuItem>
                            </Menu>
                        </>
                    :
                        <GoogleLogin 
                            clientId={config.clientId}
                            // clientId={config.localClientId}
                            buttonText='Login with Google'
                            onSuccess={ log_in }
                            onFailure={ handleLoginFailure }
                            cookiePolicy={ 'single_host_origin' }
                            responseType='code,token'
                        />
                }
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    loggedIn: state.loggedIn,
    username: state.username,
    imageUrl: state.imageUrl
})

const mapDispatchToProps = (dispatch) => ({
    login: (user) => dispatch({type: actions.LOGIN, payload: user}),
    logout: () => dispatch({type: actions.LOGOUT}),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);