const counters = [
  {
    label: "All",
    value: 1000,
  },
  {
    label: "Pending",
    value: 1000,
    color: "#EDBD00",
  },
  {
    label: "Approved",
    value: 1000,
    color: "#009CB5",
  },
  {
    label: "Accounted",
    value: 1000,
    color: "#28AE14",
  },
  {
    label: "Initiated",
    value: 1000,
    color: "#EDBD00",
  },
  {
    label: "Payment Done",
    value: 1000,
    color: "#009CB5",
  },
  {
    label: "Receipt Received",
    value: 1000,
    color: "#28AE14",
  },
];

const headers = [
  { label: "Vendor Name", key: "vendor_name" },
  { label: "Invoice Number", key: "invoice_number" },
  { label: "Invoice Date", key: "invoice_date" },
  { label: "Description", key: "description" },
  { label: "Stake Holder", key: "stake_holder" },
  { label: "Amount", key: "amount" },
  { label: "Currency", key: "currency" },
  // { label: 'Invoice Upload', key: "invoice" },
  { label: "Proforma invoice", key: "proformaInvoice" },
  { label: "Tax Invoice", key: "invoice" },
  { label: "Receipt Upload", key: "receipt" },
  { label: "Due Date", key: "due_date" },
  { label: "Status", key: "status" },
  // { label: 'Remark', key: "remark" },
];

const defaultState = {
  counters,
  data: [],
  headers,
  selectedCounter: "All",
  addShipment: false,
  editInvoice: false,
  searchVendor: "",
  searchStakeHolder: "",
  loading: false,
  updatingStatusOf: null,
  updatingRemarkOf: null,
};

const currencies = [
  "Rupee",
  " U.S. dollar",
  "Canadian dollar",
  "euro",
  "British pound",
  "New Zealand dollar",
  "Australian dollar",
  "Japanese yen",
];

const vendors = [
  "3-D Tech Pro",
  "AAYUV TECHNOLOGIES PRIVATE LIMITED",
  "Abdul Mateen",
  "ACME SERVICES PRIVATE LIMITED",
  "ADAEQUARE INFO PVT LTD",
  "Adobe Systems Software",
  "Airtel Corporate 1364383273",
  "AJITEK TECH SOLUTIONS PRIVATE LIMITED",
  "All Time Support",
  "Amazon Internet Services Private Ltd,",
  "Amazon Web Services (Market Place)",
  "Anson Careers Private Limited",
  "APPARIO RETAIL PRIVATE LIMITED",
  "APPYHAPPY SOFTWARE PRIVATE LIMITED",
  "ARK ERGO SPACE DESIGN PVT LTD",
  "Artek Energy",
  "Assembly",
  "Atlanta Systems Private Limited",
  "Atria Convergence Technologies Ltd.",
  "B.M. EMBEDDED SOLUTIONS",
  "BALAJI TECHNOLOGIES SAVEO PRIVATE LIMITED",
  "Bescom",
  "Bharti Airtel 1392439986",
  "BHARTI AIRTEL LIMITED",
  "Bharti Airtel Limited 1-2059975910160",
  "BIZKONNECT SOLUTIONS PRIVATE LIMITED",
  "Bluehill Capital Private Limited",
  "B2BRAIN INC",
  "Camera and Shorts",
  "Castle Technologies",
  "Circuit Foundary LLP",
  "Circuit Systems (India) Limited",
  "Cloudtail India Private Limited",
  "Computer Exchange Private Ltd.",
  "CONCOX (HONG KONG) LTD",
  "CONCOX GPS SYSTEMS PRIVATE LIMITED",
  "CONSTFLICK TECHNOLOGIES (OPC) PRIVATE LIMITED",
  "CONSULTING ROOMS PRIVATE LIMITED",
  "CORDON TELEMATICS PVT LTD",
  "Core Integra",
  "Croma - Kormangala2",
  "Cutt.ly",
  "DAKS ENGINEERS",
  "Darshan Electronics",
  "Darshita Aashiyana Private Limited",
  "Datayuge",
  "DHL EXPRESS (INDIA) PRIVATE LIMITED",
  "Dhruv",
  "DIGISEC360IN SOLUTIONS PRIVATE LIMITED",
  "Digital Walls Consulting Private Limited",
  "DUTCHUNCLE TECH SOLUTIONS PRIVATE LIMITED",
  "Electronic Spices",
  "ENT BUSINESS WORLD MEDIA PRIVATE LIMITED",
  "Enthire Technologies Private Limited",
  "Euclion Energy Pvt Ltd",
  "EXOTEL TECHCOM PRIVATE LIMITED",
  "EZ  TECHNOLOGIES",
  "FANCYMONK TECHNOLOGIES PVT. LTD",
  "Fastmart",
  "Figma, Inc.",
  "Flipr Innovation Labs Pvt. Ltd",
  "FLYVHEEL DIGITAL SOLUTIONS PRIVATE LIMITED",
  "GAULISH VILLAGE PRIVATE LIMITED",
  "GitScrum, Unipessoal Lda",
  "GoComet India Pvt Ltd",
  "Google India Private Limited",
  "GREYTIP SOFTWARE PRIVATE LIMITED",
  "GROWTH ASSOCIATES",
  "GURUBAL  ENGINEERING CO.PVT.LTD",
  "HARISH SHYAM BHATIA",
  "HDFC ERGO HEALTH INSURANCE LIMITED",
  "HDFC Life Insurance",
  "HEXTON TECHNOLOGIES",
  "HiringNinja Consulting Pvt Ltd",
  "HIRING EXPRESS INC",
  "HubSpot Asia Pte Ltd",
  "ICICI LOMBARD GIC LTD",
  "IDEOPE MEDIA PRIVATE LIMITED",
  "IMZ Corporate Private Limited",
  "INDEED INDIA OPERATIONS PRIVATE LIMITED",
  "Induslynk Training Services Private Limited",
  "Intuit India Software Solutions Private Limited",
  "IPV Advisors Private Limited",
  "ITILITE TECHNOLOGIES PRIVATE LIMITED",
  "ITriangle Infotech Pvt Ltd",
  "Jain SP and Company",
  "Jio Things Limited",
  "JOIN COMMERCE",
  "Kallatra Technologies Private Limited",
  "Kamikaze B2B Media",
  "KayEss Square Consulting Private Limited",
  "Kesava Techno Hub",
  "KHURANA & KHURANA, ADVOCATES AND IP ATTORNEYS",
  "Lakshmi Packaging",
  "LASARA HOTELS PRIVATE LIMITED",
  "linkedin",
  "LinkedIn Singapore Pte Ltd",
  "Lithium Energy Experts Pvt Ltd",
  "LOCONAV INDIA PRIVATE LIMITED",
  "LOGIQ LABS PRIVATE LIMITED",
  "LUMAX ITURAN TELEMATICS PRIVATE LIMITED",
  "MACFOS PVT LTD",
  "Make my Trip",
  "MANDEEP SINGH NARANG",
  "MANOJ NAIR",
  "Mark Electronics Corporation",
  "MARKON ELECTRONICS CORPORATION PRIVATE LIMITED",
  "MongoDB Limited",
  "Monster.Com(India)Pvt Ltd",
  "MSD Telematics Private Ltd.",
  "Multi Recruit",
  "MULTIPLE COMPUTECH",
  "NAMOTRONICS",
  "Nandhana Hotels",
  "Narang Computer Services",
  "Neolight",
  "NIDHI POLY PLAST",
  "Nidhi Polyplast",
  "OLYMPIC THE SPORTS WORLD",
  "OM TECHNOLOGY CENTRE",
  "ONE 97 COMMUNICATION PVT LTD",
  "ONE97 COMMUNICATIONS LIMITED",
  "One Time Vendor",
  "Onextel Media Private Limited",
  "OPEN FINANCIAL TECHNOLOGIES PRIVATE LIMITED",
  "PARCELACE TECH SOLUTIONS PVT LTD",
  "Parivesh Jain",
  "Pictor Telematics Pvt Ltd",
  "PIRG ASSET LXVI LLP",
  "Pixdig Marketing Pvt Ltd",
  "Plasmotek",
  "Postman Inc.",
  "PRAKASH KISHANDAS CHAWLA",
  "PRINTSTOP INDIA PRIVATE LIMITED",
  "Printto Documents Services Pvt Ltd",
  "PROBOTS TECHNO SOLUTIONS",
  "QubeHealth",
  "RAJ ENGINEERING INDUSTRIES",
  "RAJSHREE ELECTRONICS",
  "Rakesh Stiphen",
  "RAVI BABU VENKATRAMAIAH",
  "RAZORPAY SOFTWARE PRIVATE LIMITED",
  "Recon Business Advisory Pvt. Ltd.",
  "Relative Venture",
  "RENU  MEGOTIA",
  "Rishal",
  "RV College of Engineering",
  "Saveo Healthtech Pvt. Ltd.",
  "SA Cool Tech Solution",
  "SBI Card Payment",
  "SCALEUP EDUTECH PVT LTD",
  "SDOD Technologies Private Limited",
  "Sealed Energy Systems",
  "Searce Cosourcing Services Pvt. Ltd",
  "SEISO SUPPLY CHAIN PRIVATE LIMITED",
  "Sendgrid",
  "SendGrid USD",
  "SendinBlue",
  "Shanti Telemobiles",
  "Shashi Kumar",
  "ShipsGo",
  "SHIPWAY TECHNOLOGY PVT LTD",
  "SHREYA ENTERPRISES",
  "SHREYASH RETAIL PRIVATE LIMITED",
  "Shri KP & Associates",
  "SHRISHTI INFRA SOLUTIONS PRIVATE LIMITED",
  "Shubh electronics",
  "SignE Technologies",
  "Silverline Electronics",
  "Slack Technologies Limited",
  "Socialli",
  "Sogo Computers Private Limited",
  "SPICE JET LIMITED",
  "SPOTTABL TECHNOLOGIES PRIVATE LIMITED",
  "SPURGE IT SERVICES (OPC) PVT LTD",
  "SR Tax Consultancy",
  "SRINIVASA GLASS & PLAYWOODS",
  "Studio Jitsah",
  "Sumo Technologies Private Limited",
  "Sunrise Consultancy Services (Fundoodata)",
  "SWAI TECHNOLOGIES PRIVATE LIMITED",
  "Swastik Electronics",
  "TALKD Private Limited",
  "TECHMASH SOLUTIONS PRIVATE LIMITED",
  "TechnoLabz",
  "TECHOLABZ",
  "TELEMERGE IT SERVICES PVT LTD",
  "TELENITY SYSTEMS SOFTWARE INDIA PRIVATE LIMITED",
  "THANEVEDHU SOLUTION",
  "THE INSTITUTE OF CHARTERED ACCOUNTANTS OF INDIA",
  "THINGBITS ELECTRONICS PRIVATE LIMITED",
  "Tracknovate Mobile Resource Management Pvt. Ltd",
  "Transport Times",
  "Trawealth India Private Limited",
  "TRUCKHALL PRIVATE LIMITED (SuperProcure)",
  "TU Retail Service Private Ltd.",
  "Uchi Embedded Solutions",
  "Value Point Systems Private Limited",
  "ValueFirst Digital Media Private Limited",
  "VENUS STATIONERS",
  "Vishal Electronics",
  "VODAFONE IDEA BUSINESS SERVICES LIMITED",
  "VODAFONE IDEA LIMITED",
  "Vodafone Shared Service (Gsuit)",
  "VXCESS SOLUTIONSS",
  "Webflow, Inc.",
  "Webtel Electrosoft Pvt Ltd",
  "Zeliot Connected Services Private Limited",
  "Zoho Technologies Pvt. Ltd.",
  "Zoominfo",
  "Zumigo India Private Limited",
];

const newInvoice = [
  {
    label: "Vendor Name",
    key: "vendor_name",
    type: "select",
    options: vendors,
    defaulValue: "Webflow, Inc.",
  },
  { label: "Invoice Number", key: "invoice_number", type: "text" },
  { label: "Invoice Date", key: "invoice_date", type: "date" },
  { label: "Description", key: "description", type: "text" },
  { label: "Stake Holder", key: "stake_holder", type: "text" },
  { label: "Amount", key: "amount", type: "text" },
  {
    label: "Amount Currency",
    key: "currency",
    type: "select",
    options: currencies,
    defaulValue: "Rupee",
  },
  { label: "Due Date", key: "due_date", type: "date" },
  {
    label: "Invoice Type",
    key: "invoiceType",
    type: "radio",
    options: ["Proforma Invoice", "Tax Invoice"],
  },
  { label: "Invoice Upload", key: "invoice", type: "file" },
];

newInvoice.forEach((field) => {
  defaultState[field.key] = field.defaulValue || null;
});
defaultState["proformaInvoice"] = false;

const authorized = {
  // 'rohit@intugine.com': ["Pending->Approved", "Approved->Accounted", "Accounted->Initiated", "Initiated->Payment Done", "Payment Done->Receipt Received"],
  "rohit@intugine.com": [
    "Pending->Approved",
    "Initiated->Payment Done",
    "edit",
  ],
  "mrinal@intugine.com": [
    "Pending->Approved",
    "Initiated->Payment Done",
    "edit",
  ],
  "aakash@intugine.com": [
    "Approved->Accounted",
    "Payment Done->Receipt Received",
    "edit",
  ],
  "smsunil@intugine.com": [
    "Approved->Accounted",
    "Payment Done->Receipt Received",
    "edit",
  ],
  "accounts@intugine.com": [
    "Accounted->Initiated",
    "Payment Done->Receipt Received",
    "edit",
    "Initiated->Payment Done",
  ],
};

export default {
  counters,
  defaultState,
  newInvoice,
  authorized,
  headers,
};
