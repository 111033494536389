import actions from './actions';

const initialState = {
    loggedIn: false,
    username: null,
    email: null,
    imageUrl: null,
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.LOGIN: 
            return {
                ...state,
                loggedIn: true,
                username: action.payload.username,
                email: action.payload.email,
                imageUrl: action.payload.imageUrl,
            }
        case actions.LOGOUT: return initialState;
        default: return state;
    }
}