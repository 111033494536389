import logo from './logo.svg';
import './App.css';
import { Grid } from '@material-ui/core';

import AuthCheck from './auth-check/auth-check';
import DashboardContainer from './modules/dashboard-container/dashboard-container';

function App(props) {

  // if (!props.loggedIn) props.location.history.push("/login")

  return (
    // <AuthCheck />
    <Grid container>
      <Grid item xs={12}>
        <DashboardContainer />
      </Grid>
    </Grid>
  );
}

export default App;