import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress, Grid, MenuItem, TextField } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import moment from 'moment';
import { format as dateFormat } from "date-fns";

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
  },
  container: {
    maxHeight: 440,
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: "4px"
  },
});

export default function DataTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
  return (
    <Grid container justify="center" style={{position: "relative"}}>   
        <div style={{position: "absolute", height: "100px", width: "100vw", background: "#43425D", zIndex: -1}}>

        </div>
        <Grid item xs={11}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                          <TableRow>
                          {
                            props.headers.map((header) => (
                                <TableCell
                                  key={header.value}
                                  align="center"
                                >
                                  {header.label}
                                </TableCell>
                            ))
                          }
                          </TableRow>
                      </TableHead>
                    <TableBody>
                        {
                          props.data
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => (
                              <TableRow hover key={row._id}>
                              {
                                props.headers.map((header) => (
                                  <TableCell key={header.key} align="center">
                                    {
                                      header.key === "invoice" ? 
                                        <> 
                                            { row[header.key] ?.Location ? <a download href={row[header.key].Location} style={{color: "black", cursor: "pointer"}}><GetAppIcon/></a> :                                          
                                            <PublishIcon onClick={() => props.uploadTaxInvoice(row._id)} style={{cursor: "pointer"}}/>  }
                                        </> 
                                      : header.key === "proformaInvoice" ? 
                                            <> 
                                            { 
                                              row[header.key] ?.Location ? <a download href={row[header.key].Location} style={{color: "black", cursor: "pointer"}}><GetAppIcon/></a> :  
                                              <PublishIcon onClick={() => props.uploadProformaInvoice(row._id)} style={{cursor: "pointer"}}/> }                                         
                                        </> 
                                    
                                      :  
                                        header.key === "receipt" ?
                                          <>
                                            <PublishIcon onClick={() => props.uploadReceipt(row._id)} style={{cursor: "pointer"}}/>
                                            { row[header.key] ? <a download href={row[header.key].Location} style={{color: "black", cursor: "pointer"}}><GetAppIcon/></a> : "" }
                                          </>
                                        :
                                        header.key === "status" ?
                                          (props.loading && props.updatingStatusOf === row._id ?
                                          <CircularProgress />
                                          :
                                          <TextField value={row.status} select variant="outlined" onChange={e => props.updateStatus(row._id, e.target.value, row.status, row.vendor_name)} label="Status">
                                            {
                                              ["Pending", "Approved", "Accounted", "Initiated", "Payment Done", "Receipt Received"].map(s => (
                                                <MenuItem value={s}>{s}</MenuItem>
                                              ))
                                            }
                                          </TextField>)
                                        :
                                          header.key === "edit" ? 
                                            <EditIcon onClick={() => props.editInvoice(row)} style={{cursor: "pointer"}}/>
                                          :
                                            ["due_date", "invoice_date"].includes(header.key) ?
                                              row[header.key] ? dateFormat(new Date(row[header.key]), 'dd-MM-yyyy') : "NA"
                                            :
                                              row[header.key]
                                    }
                                  </TableCell>
                                ))
                              }
                              </TableRow>
                          ))
                        }
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={props.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </Grid>
    </Grid>
  );
}
