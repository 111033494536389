import { Grid } from '@material-ui/core';
import utils from './utils';

export default function Counters(props) {
    return (
        <Grid container style={{background: "#43425D", padding: "30px 0px 30px 0px"}}>
            <Grid item xs={12}>
                <Grid container justify="center" row wrap>
                    {
                        (props.counters || []).map((counter, index) => (
                            <Grid item xs={12/props.counters.length} key={index} style={{margin: "10px"}}>
                                <Grid container style={{
                                        padding: "5px",
                                        border: `2px solid ${props.selectedCounter === counter.label ? "white" : (utils.getColor(counter.label) || "white")}`,
                                        borderLeft: `12px solid ${props.selectedCounter === counter.label ? "white" : (utils.getColor(counter.label) || "white")}`,
                                        borderRadius: "8px",
                                        borderTopLeftRadius: props.selectedCounter === counter.label ? "8px" : "0",
                                        borderBottomLeftRadius: props.selectedCounter === counter.label ? "8px" : "0",
                                        color: props.selectedCounter === counter.label ? "#4949F0" : "#9BC5F8",
                                        fontWeight: "600",
                                        backgroundColor: props.selectedCounter === counter.label ? "white" : '#43425D',
                                        cursor: "pointer",
                                        width: "180px"
                                    }}
                                    onClick={_ => props.select(counter.label)}
                                    justify="space-between"
                                    alignItems="center"
                                    direction="column"
                                >
                                    <Grid item style={{
                                        margin: "10px 0 5px 0"
                                    }}>{counter.label}</Grid>
                                    <Grid item style={{
                                        margin: "5px 0 10px 0",
                                        fontSize: "32px"
                                    }}>{counter.value || 0}</Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}