const getColor = (label) => {
    const colorMap = {
        'Pending': '#EDBD00',
        'Approved': '#009CB5',
        'Accounted': '#28AE14',
        'Initiated': '#EDBD00',
        'Payment Done': '#009CB5',
        'Receipt Received': '#28AE14',
    }
    return colorMap[label] || "white";
}

export default {
    getColor,
}