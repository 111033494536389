import { Grid } from '@material-ui/core';
import Navbar from './components/navbar/navbar';
import Home from './components/home/home';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DashboardContainer(props) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Navbar error={v => toast.error(v)}/>
            </Grid>
            <Grid item xs={12}>
                <Home error={v => toast.error(v)}/>
            </Grid>
            <ToastContainer />
        </Grid>
    );
}