import {
  withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const WhiteTextField = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: 'white',
    },
    '& input:valid:focus + fieldset': {
      borderColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
          color: "pink"
        },
      },
  },
})(TextField);

export default WhiteTextField;
